import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Research Profile - Kimberly Plomp, PhD</title>
            <meta name="description" content="Research profile" />
            <meta name="keywords" content="research,profile,about" />
        </Helmet>

        <BannerLanding title="Research Profile" subtitle="Bioarchaeologist specializing in palaeopathology, evolutionary medicine, and skeletal variation" />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Kimberly A. Plomp, PhD</h2>
                    </header>
                    <p>I am a biological anthropologist whose work intersects with several related fields, including bioarchaeology, palaeopathology, paleoanthropology, and evolutionary medicine. My main research interests align under an overriding theme: investigating how evolutionary adaptations have shaped modern human skeletal variation and how this variation influences human health and disease. To answer these questions, I analyze the morphological variation of the skeleton of archaeological and modern humans, extant non-human primates, and extinct fossil hominins using cutting-edge approaches, such as photogrammetry and geometric morphometrics. The outcomes of my research are not only relevant for the field of biological anthropology, but also have the potential to impact the lives of people today. Check out ‘<Link to="/evolvinghealth">Evolving Health</Link>’ for details on my edited volume called <i>Palaeopathology and Evolutionary Medicine: an integrated approach.</i></p>
                </div>
            </section>
            <section className="spotlights">
                <section id="two">
                    <StaticImage src="../assets/images/kim_skeleton.webp" alt="Kim Plomp skeleton" className="image" objectFit="contain" />
                    <div className="content">
                        <div className="inner">
                            <h3>Spinal health, vertebral shape, and bipedalism</h3>
                            <p>I have a number of projects on the go, but the one I am most passionate about is called the Evolutionary Shape Hypothesis (ESH). This project investigates the relationship between vertebral morphology, bipedalism (i.e., walking on two legs), and human spinal health by comparing the morphology of vertebrae of humans with and without spinal pathologies with those of non-human primates and fossil hominins. Rarely does one meet an adult who has not experienced back pain at least once in their lives. In fact, spinal problems afflict humans more commonly than any other animal. For decades scholars have suggested that this is due to our unique mode of locomotion, bipedalism, and the stresses it places on our spines. In 2009, my collaborators and I posited that vertebral shape may be a mediating factor in the relationship between bipedalism and spinal health and if so, that this relationship may be related to our evolutionary history.</p>
                            <p>Over the years, we have developed and tested this hypothesis using archaeological and comparative data and found evidence that human vertebral shape variation and its evolutionary origins are important factors in spinal health. This work is highlighted by two key papers – <a href="https://doi.org/10.1186/s12862-015-0336-y" target="_blank" rel="noreferrer">Plomp et al. 2015</a> and <a href="https://doi.org/10.1093/emph/eoaa003" target="_blank" rel="noreferrer">Plomp et al. 2020</a> – which together form the foundation of the ESH. This hypothesis proposes that human vertebral shape variation can be visualised as a spectrum, where the extremities have shape traits that are ancestral or are more pronounced adaptations to bipedalism, and where an individual’s vertebrae sit on this spectrum may critically influence their spinal health. This research has been covered in the media, with some helpful write-ups provided at <a href="https://www.iflscience.com/health-and-medicine/evolution-blame-lower-back-pain/" target="_blank" rel="noreferrer">IFLScience</a>, <a href="https://www.thenakedscientists.com/articles/science-news/chimp-spines-cause-backpain" target="_blank" rel="noreferrer">The Naked Scientists</a>, and <a href="https://www.torontotelegraph.com/news/264223197/study-links-back-pain-to-evolution-spine-shape" target="_blank" rel="noreferrer">The Toronto Telegraph</a>.</p>
                        </div>
                    </div>
                </section>
                <section id="three">
                    <StaticImage src="../assets/images/kim_bones.webp" alt="Kim Plomp bone fragments" className="image" objectFit="contain" />
                    <div className="content">
                        <div className="inner">
                            <h3>The remains of the Dagohoy Rebellion</h3>
                            <p>One exciting project I am currently working on, along with student members of my lab, is the analysis of human and animal skeletal remains found on the floor of a famous cave in Danao, Bohol, Philippines. This cave was the known hide-out of Francisco Dagohoy, a famous rebel who led the longest revolt again Spanish rule in the Philippines and was named after him – the Dagohoy cave. Francisco Dagohoy and his soldiers were able to evade capture for 85 years by living in the cave system in and around Danao, Bohol. Oral history describes how Dagohoy cave was their main hide-out and the location of the final showdown between the rebels and the Spanish army. The rebellion came to an end when the Spanish used fire to force evacuation of the cave.</p>
                            <p>During a rescue operation in 2005, local tourism employees were able to collect fragmented bones from the floor of Dagohoy cave. In 2023, my students and I were invited to visit Danao and identify if any of the bones belonged to humans, and potentially, the Dagohoy soldiers. We were to identify the remains of a minimum of 21 human individuals mixed in with fragments of various other animal bones.</p>
                            <p>In early 2024, we will return to Danao to collect samples for radiocarbon dating to attempt to determine how long the bones have been in the cave and potentially support the suggestion that they are the remains of the rebels. If they are not the rebels, this opens a new question as to who the 21 individuals living in the small cave were…</p>
                        </div>
                    </div>
                </section>
                <section id="four">
                    <StaticImage src="../assets/images/kim_dig.webp" alt="Kim Plomp archaeological dig" className="image" objectFit="contain" />
                    <div className="content">
                        <div className="inner">
                            <h3>Human variation and migration</h3>
                            <p>I am also working on a large project that was funded by the <a href="https://cordis.europa.eu/article/id/413251-reading-skulls-to-learn-about-the-founders-of-iceland" target="_blank" rel="noreferrer">H2020 Marie Skłodowska-Curie Actions</a>. This project analysed cranial shape variation of archaeological European populations to trace medieval migrations and the movement of peoples throughout Northern Europe. Cranial shape can be used as a proxy for DNA and can show affinity between individuals and their genetic populations. My collaborators and I have used this method to answer two important questions that have been debated in archaeology and history for decades. First, we analysed the shape of the base of the cranium of archaeological remains of people from Iceland, Scandinavia, and the British Isles to determine who participated in the initial founding of the island. To date, there have been two main hypotheses about who founded Iceland. One proposed that the settlers were largely Vikings from Norway, while the other argued that the Vikings were joined by people from Scotland and Ireland. We found that more people from Southern Britain (i.e., modern England) played a more important role in founding Iceland than had previously been recognized. Specifically, we found that 30% of the settlers shared affinity with people from Scandinavia, 27% shared affinity with people from Scotland and Ireland, and 38% shared affinity with people from Southern Britain.</p>
                            <p>We also used the same methods to investigate the Anglo-Saxon migration into Britain in the early medieval period. It has been debated in archaeology whether this migration resulted in a replacement of the indigenous British population or if the local people adopted the Anglo-Saxon culture. We found that 66 - 75% of the Early Anglo-Saxon individuals were of mainland European ancestry, while 25 - 30% were of local ancestry. In contrast, we found that 50 – 70% of the Middle Anglo-Saxon Period individuals were of local ancestry, while 30 – 50% were of mainland European ancestry. It is unclear why there is a change between these two periods, but regardless of the cause of the change in composition, it is clear from our results that being an Anglo-Saxon was more a matter of language and culture than genetics. This study has also been reported in media and useful write-ups can be found at <a href="https://theconversation.com/ancient-skulls-show-anglo-saxon-identity-was-more-cultural-than-genetic-163338" target="_blank" rel="noreferrer">The Conversation</a>, <a href="https://www.theglobeandmail.com/canada/article-human-skulls-reveal-diverse-roots-of-anglo-saxon-ancestry/" target="_blank" rel="noreferrer">The Globe and Mail</a>, and <a href="https://www.archaeology.org/news/9815-210629-britain-anglo-saxon" target="_blank" rel="noreferrer">Archaeology Magazine</a>.</p>
                        </div>
                    </div>
                </section>
            </section>
            <section id="five">
                <div className="inner">
                    <h3>Other projects</h3>
                    <p>I am also involved in various other projects, including a collaboration the Max Planck Institute of Geoanthropology through their ERC-funded project <a href="https://www.shh.mpg.de/1489404/pantropocene-group" target="_blank" rel="noreferrer">PANTROPOCENE</a>, and working towards growing the use of forensic archaeology in the Philippines.</p>
                    <p>If you have any questions or would like to chat more about my research, please feel free to <Link to="/contact">reach out</Link>!</p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Landing
